import React, { useEffect } from 'react';
import CheckAvailability from '../components/CheckAvailability';
import { useNavigate } from 'react-router-dom';

const CheckAvailabilityPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    navigate('/')
  }, []);

  return (
    <div>
      <CheckAvailability />
    </div>
  );
};

export default CheckAvailabilityPage;